// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBHa7g1iIe2mgQNP93dJqL766app4nxK3o",
  authDomain: "medops-2e778.firebaseapp.com",
  projectId: "medops-2e778",
  storageBucket: "medops-2e778.appspot.com",
  messagingSenderId: "742935948010",
  appId: "1:742935948010:web:912269bbac8718a7acc593",
  measurementId: "G-M56M2M5YXR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);