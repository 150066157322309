import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

import {analytics} from '../../firebase/firebase';
import { logEvent } from "firebase/analytics";

export default function QuoteSection({show, handleClose, handleShow}) {
  return (
    <>
    <Row className="mt-4 mb-4">
         <Image src="./quote_photo.png" className="center-block p-4"  style={{width:"300px"}} />
    </Row>
    <Row className="mt-4 mb-4 text-center">
        <Col xs="12" md="8" className="mx-auto">
        <h1>El mejor cariño que le podemos dar a nuestra mascota es su salud.</h1>
        <Button className="center-block" onClick={()=>{handleShow(); logEvent(analytics, "quote_button")}}>Buscar veterinario</Button>
        </Col>
    </Row>
    </>
  )
}
