import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Navbar';

export default function MyNavbar() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary" fixed="top">
            <Container>
              <Navbar.Brand href="/">
                <Image src="/mascotalia_logo.png" width="170px"/>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  <Nav.Link href="#busca">Busca tu clínca</Nav.Link>
                  <Nav.Link href="#benefits">Beneficios</Nav.Link>
                  <a href="https://app.mascotalia.com.mx">
                 <Button className="btn btn-primary">Publicar mis servicios</Button>
                 </a> 
                  {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
  )
}
