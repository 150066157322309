import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";


export default function QuoteSection() {
  return (
 <Container fluid className="footer pb-4 pt-4">
    <Container>
   <Row className="mt-4 mb-4 p4">
    <Col className="mt-4" xs="12" sm="4">
        <Image className="center-block" src="./mascotalia_logo.png" width="200"/>
    </Col>
    <Col className="text-center mt-4" xs="12" sm="4">
        Todos los derechos reservados<br/>
        <a href="/privacy">Aviso de privacidad</a><br/>
        <a href="/terms">Terminos y condiciones</a>
    </Col>
    <Col className="text-sm-end text-center mt-4" xs="12" sm="4">
    <b>LAYABIT FINANCE SAPI DE CV</b><br/>
    <a href="contacto@mascotalia.com.mx">contacto@mascotalia.com.mx</a><br/>
    Montes Urales 424,<br/>
    Lomas de Chapultepec,<br/>
    11000, CDMX
    </Col>
   </Row>
   </Container>
   </Container>
  )
}
