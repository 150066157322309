import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { PiDogFill } from 'react-icons/pi';
import { BsFillCalendar2DateFill } from 'react-icons/bs';
import { MdInventory } from 'react-icons/md';
import { MdOutlineViewTimeline } from 'react-icons/md';
import { RiBillFill } from 'react-icons/ri';
import { BsClipboardCheckFill } from 'react-icons/bs';
import { BsFillChatLeftHeartFill } from 'react-icons/bs';
import { RiCustomerService2Line } from 'react-icons/ri';

import Footer from '../components/landing-pet-owner/Footer'


import {db, analytics} from '../firebase/firebase';
import { collection, addDoc} from "firebase/firestore"; 
import { logEvent } from "firebase/analytics"; 




const MyNavbar = ({handleClose, handleShow}) => {
  return (
    <Navbar expand="lg" className="bg-body-tertiary" fixed="top">
      <Container>
        <Navbar.Brand href="/vets">
          <Image src="/mascotalia_logo.png" width="170px" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#inicio">Inicio</Nav.Link>
            <Nav.Link href="#solutions">Soluciones</Nav.Link>
            <Nav.Link href="#new-patients">Obtener nuevos pacientes</Nav.Link>
            <Button onClick={handleShow}>Crear cuenta</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const HeroSection = ({handleClose, handleShow}) => {
  return(
  <Container
    fluid
    className="header-container mt-4"
    style={{
      background: `url('${process.env.PUBLIC_URL}/header_img_vets.png'), linear-gradient(168deg, #00A35E 0%, #18C27B 100%)`,
      backgroundSize: "cover",
      backgroundPosition: "top left",
    }}
    id="inicio"
  >
    <Container className="h-100" style={{marginTop:50}}>
      <Row className="align-items-center h-100 white-text">
        <Col xs="12" md="6" className="text-hero">
          <h1 className="upper">Mascotalia te ayuda a retener y atraer  pacientes</h1>
          <h5>
          Mas que un software que te ayuda a tu gestión, <br/>
          te ayuda a construir tu marca con base en la opinión de tus pacientes actuales y los nuevos.
          </h5>
          <Button className="mt-2"variant="secondary" onClick={()=>{handleShow(); logEvent(analytics, "hero_button")}}>Crear una cuenta</Button>
        </Col>
        <Col className="p-4" xs="12" md="6">
          <Image src="./mascotalia_platform.png" className="img-fluid p-4 hover-animation"></Image>
        </Col>
      </Row>
    </Container>
  </Container>
  )
};



const SolutionsSection = ()=>{
  const solutions = [
    {
      icon:<PiDogFill/>,
      title:"Record de pacientes",
      description: "Lleva control de todos los  pacientes y sus dueños, con fotografías, videos y eventos clínicos de cada paciente. Puedes importar tu record a Mascotalia  usando nuestra plantilla de excel."
    },
    {
      icon:<BsFillCalendar2DateFill/>,
      title:"Agenda de citas",
      description: "Actualiza y simplifica la agenda de citas a través de nuestro sistema con recordatorio automático para tus clientes para reducir las cancelaciones de ultimo minuto."
    },
    {
      icon:<MdInventory/>,
      title:"Inventario",
      description: "Lleva control del inventario de tu negocio y recibe alertas cuando un producto esté por acabarse."
    },
    {
      icon:<MdOutlineViewTimeline/>,
      title:"Guardias",
      description: " Organiza las guardias y los horarios del personal de tu negocio veterinario para que todo tu equipo esté informado de su turno."
    },
    {
      icon:<RiBillFill/>,
      title:"Cotizaciones",
      description: "Se más eficiente  generando cotizaciones con plantillas predefinidas. Gana control estableciendo reglas y descuentos  en productos y servicios."
    },
    {
      icon:<BsClipboardCheckFill/>,
      title:"Check-in",
      description: "Tus colaboradores pueden hacer check-in desde su app solamente estando a 20 metros de tu negocio para que tengas visibilid de su hora de entrada y salida."
    },
    {
      icon:<RiCustomerService2Line/>,
      title:"Seguimiento a clientes",
      description: "Envía mensajes automáticos  tus clientes para obtener seguimiento de su última visita."
    },
    {
      icon:<BsFillChatLeftHeartFill/>,
      title:"Seguimiento a clientes",
      description: "Lleva un registro las interacciones con tu cliente usando notas, fotos y videospara asegurarte que todo el equipo esté enterado del perfil de cliente."
    }
  ]
  return(
    <Container className="section-margin" id="solutions">
    <Row className="margin-fix">
   
        {solutions.map((solution, idx) =>{
          return(
            <Col md="3" key={idx}>
            <Card className="mt-4 p-4 text-center solution-card hover-animation">
             <h1 className="mt-4 solition-card">{solution.icon}</h1>
             <h5 className="bold">{solution.title}</h5>
             <p>{solution.description}</p>
             <button className=" btn btn-link" onClick={()=>{logEvent(analytics, `services_button_${solution.title}`)}} >Saber más...</button>
          </Card>
          </Col>
          )})}
     
     
    </Row>
    </Container>
  )
}


const NewPatientsSection = ({handleClose, handleShow})=>{
  return (
    <Container fluid className="green-bg white-text mt-4" id="new-patients">
      <Container>
      <Row className="align-items-center h-100">
        <Col md="7" xs="12">
          <h1>¿Cómo contacto con nuevos pacientes?</h1>
          <p>Al suscribirte a Mascotalia, tu negocio aparece listado en automático en nuestro directorio para que tengas exposición a miles de potenciales nuevos pacientes.</p>
          <Image src="./vet_place.png"  width="80%" className="hover-animation" />
        </Col>
        <Col md="5" xs="12">
          <Image src="./female_vet.png" className="img-fluid pt-3 "/>
        </Col>
      </Row>
      </Container>
    </Container>
  )
}

const WhySection = ({handleShow, handleClose})=>{
  return(
  <Container className="section-margin">
    <Row className="align-items-center h-100">
      <Col xs="12" md="8">
        <Card className="green-bg white-text p-4">
          <h1 className="p-3">¿Por qué usar Mascotalia?</h1>
          <p className="p-3">Enfocarte en  trato a tus pacientes y en crecer tu negocio, y déjanos las tareas administrativas y lo que quita tiempo a Mascotalia.
            <br/><br/>
            Tienes todo lo que necesitas 100% en línea disponible en todo momento.</p>
            <Button variant="secondary"  onClick={()=>{handleShow(); logEvent(analytics, "why_section_button")}}>Crear cuenta</Button>
        </Card> 
      </Col>
      <Col xs="12" md="4">
        <Image src="./vet_why.png" className="img-fluid p-4 hover-animation" />
      </Col>
    </Row>
  </Container>
  )
}


const ModalContactVets = ({show, handleClose, handleShow})=>{

  

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [clinicName, setClinicName] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [software, setSoftware] = useState('');
    const [clinicType, setClinicType] = useState('');
  

    const formData ={
      email: email,
      clinicName: clinicName,
      name: name,
      phone: phone,
      software: software,
      clinicType: clinicType
    }
    

   async function sendData(e) {
      setLoading(true);
      e.preventDefault();
      await addDoc(collection(db, "vet-leads"), formData).then(()=>{
        handleClose();
        alert("Lo sentimos, todavía no estamos en tu zona. Espéranos muy pronto.")
        logEvent(analytics, 'vet_data', formData)
        setLoading(false)
      }).catch(err=> setLoading(false));
  }
          
        
    
    return (
      <>
        <Modal show={show} onHide={handleClose}  size="lg">
        <Form onSubmit={e=>sendData(e)}>
          <Modal.Header closeButton>
            <Modal.Title>Regálanos tus datos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Image src="./mascotalia_logo.png" className="center-block p-2" width="170px"/>
            <p className="text-center">Ingresa tus datos.</p>
          <Row>
            <Col>
           <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Nombre del negocio</Form.Label>
                <Form.Control required type="text" placeholder="Nombre Completo" onChange={(e)=>setClinicName(e.target.value)}/>
            </Form.Group>
            </Col>

            <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Tipo de negocio</Form.Label>
              <Form.Select required aria-label="Default select example" onChange={e => setClinicType(e.target.value)}>
              <option>Selecciona uno</option>
              <option value="particular">Veterinario Particular</option>
              <option value="consultorio">Consultorio Veterinario</option>
              <option value="clinica">Clínica Veterinaria</option>
              <option value="hospital_veterinario">Hospital Veterinario</option>
            </Form.Select>
          </Form.Group>
          </Col>
          </Row>
            <Row>
              <Col>
           <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Tu Nombre</Form.Label>
                <Form.Control required type="text" placeholder="Nombre Completo" onChange={(e)=>setName(e.target.value)}/>
            </Form.Group>
            </Col>
            <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control required type="email" placeholder="hola@mascotalia.com" onChange={(e)=>setEmail(e.target.value)}/>
            </Form.Group>
            </Col>
           </Row>
           <Row>
            <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Teléfono Celular</Form.Label>
                <Form.Control required pattern="[0-9]{10}" type="text" minLength="10" maxLength="10" placeholder="55 55 55 55 55" onChange={(e)=>setPhone(e.target.value)}/>
            </Form.Group>
            </Col>
            <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Software que uso actualmente</Form.Label>
              <Form.Select aria-label="Default select example" onChange={e=> setSoftware(e.target.value)}>
              <option>Selecciona uno</option>
              <option value="ninguno">Ninguno</option>
              <option value="excel">Excel</option>
              <option value="onward_vet">Onward Vet</option>
              <option value="daysmart_vet">Day Smart Vet</option>
              <option value="custom_software">Software a la medida</option>
            </Form.Select>
          </Form.Group>
            </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit" disabled={loading} >
             Enviar 
            </Button>
          </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
  


export default function LandingVet() {
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
  <>
  <MyNavbar handleClose={handleClose} handleShow={handleShow}/>
  <HeroSection handleClose={handleClose} handleShow={handleShow} />
  <SolutionsSection />
  <NewPatientsSection handleClose={handleClose} handleShow={handleShow}/>
  <WhySection handleClose={handleClose} handleShow={handleShow} />
  <Footer/> 
  <ModalContactVets show={show} handleClose={handleClose} handleShow={handleShow}/>
  </>
  );
}
