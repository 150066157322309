import React from 'react'
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import MyNavbar from '../components/landing-pet-owner/MyNavbar';
export default function Privacy() {
  return (
    <>
    <MyNavbar/>
    <Container>
      <Card className="p-4" style={{marginTop:"90px"}}>
      Aviso de Privacidad Sitio Web Integral<br/><br/>
LAYABIT FINANCE S.A.P.I DE C.V (nombre comercial: Mascotalia) con domicilio enC. Montes Urales 424,
Lomas de Chapultepec, 11000,CDMX, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
<br/><br/>
Departamento de Privacidad<br/>
Para atender cualquier solicitud acerca del ejercicio de sus derechos ARCO, para la revocación del consentimiento que nos haya otorgado del tratamiento de sus datos personales, para limitar el uso o divulgación de sus datos, o acerca de las finalidades para el tratamiento de sus datos personales, ponemos a su disposición nuestro Departamento de Privacidad en los siguientes medios de contacto:
<br/><br/>
Departamento de Privacidad<br/>
Correo electrónico: contaco@mascotalia.com.mx
<br/><br/>
Para poder comunicarnos con usted, necesitamos los siguientes datos personales y de contacto en su solicitud:<br/>

Nombre del titular<br/>

Carta de autorización en caso de que la solicitud la haga otra persona que no sea el titular<br/>

Copia de identificación del titular<br/>

Correo electrónico<br/>

Teléfono<br/>

Después de recibir su solicitud, recibirá nuestra respuesta en un plazo máximo de veinte días hábiles por los medios de contacto que nos proporcione.
<br/><br/>
¿Para qué fines utilizaremos sus datos personales?<br/>
Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:

Para fines de contacto

Para permitir el registro en el sitio

Para acceder al contenido del sitio

De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle mejor atención:

Para fines de publicidad mercadotecnia o prospección comercial

En caso de que no desee que sus datos personales sean tratados para estos fines adicionales, lo podrá indicar en el medio a través del cual nos proporcione sus datos personales, seleccionando la opción correspondiente.
<br/><br/>

La negativa para el uso de sus datos personales para estas finalidades adicionales no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.
<br/><br/>
¿Qué datos personales utilizaremos para estos fines?<br/>
Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad, utilizaremos los siguientes datos personales:<br/>

Datos de contacto<br/>

Datos de identificación<br/>
<br/>
¿Cómo puede Acceder, Rectificar o Cancelar sus datos personales, u Oponerse a su uso?<br/>
Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la normativa (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.

<br/><br/>
Si usted desea ejercer sus derechos ARCO, lo podrá hacer contactándose con nuestro Departamento de Privacidad en los medios establecidos en el presente aviso.
<br/><br/>
¿Cómo puede revocar su consentimiento para el uso de sus datos personales?<br/>
Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar qué para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
<br/><br/>
Para conocer el procedimiento y requisitos para la revocación de su consentimiento, usted podrá ponerse en contacto con nuestro Departamento de Privacidad a través de los medios especificados en el presente aviso.
<br/><br/>
¿Cómo puede limitar el uso o divulgación de su información personal?<br/>
Si usted desea limitar el uso o divulgación de su información personal podrá solicitarlo a nuestro Departamento de Privacidad a través de los medios especificados en el presente aviso.
<br/><br/>

Adicionalmente, podemos poner a su disposición procedimientos y mecanismos específicos mediante los cuales puede limitar el uso de su información personal. Estos procedimientos y mecanismos específicos se informarán a través de los medios que utilicemos para comunicarnos con usted u otros que consideremos adecuados.
<br/><br/>
Uso de Cookies y tecnologías de rastreo<br/>
Las cookies son pedazos de información en forma de pequeños archivos que se localizan en su disco duro y son generados por nuestro sitio web. Estos archivos hacen que la interacción con nuestro sitio sea más rápida y fácil, recordando preferencias, datos de comportamiento o datos de registro en nuestro sitio. La información que contienen puede ser utilizada cuando usted visita nuestro sitio y sitios externos.
<br/><br/>

Algunas páginas o correos nuestros pueden contener pequeñas imágenes invisibles llamadas “web beacons” o “pixel tags”. Los web beacons rastrean su comportamiento en una página o correo de forma similar a las cookies.
<br/><br/>

Le informamos que las cookies y otras tecnologías de rastreo las utilizamos para las siguientes finalidades:<br/>

Para permitir el registro e inicio de sesión en nuestro sitio<br/>

Para el correcto funcionamiento de la tienda en línea, guardar los artículos que ha agregado al carrito de compras y permitir el pago de los mismos<br/>

Para el registro y análisis de comportamiento dentro de nuestro sitio con fines estadísticos y de calidad<br/>

Además, podemos utilizar la información para fines publicitarios y de remarketing. El remarketing es un método que muestra nuestros anuncios en sitios ajenos a éste en función de visitas pasadas a nuestro sitio. Los anuncios se pueden mostrar en forma de banners o publicaciones sugeridas.

<br/><br/>
Puede solicitar la inhabilitación del uso de cookies para publicidad, mediante la página de Network Advertising Initiative (inglés):
<br/>
www.networkadvertising.org/choices/<br/>
Para inhabilitar los anuncios de Google:<br/>
www.google.com/settings/ads/?hl=es<br/>

Igualmente puede consultar las políticas de privacidad de las compañías que utilizamos para fines publicitarios y de remarketing:<br/>

Google: www.google.com/intl/es_mx/policies/privacy/<br/>

Facebook: www.facebook.com/policies/cookies/<br/>

Twitter: twitter.com/privacy?lang=es<br/>
<br/><br/>
Si usted prefiere deshabilitar las cookies completamente, lo puede hacer en la mayoría de los exploradores de internet. Tenga en cuenta que este sitio puede dejar de funcionar correctamente al hacerlo. En las versiones más recientes de los exploradores Mozilla Firefox, Internet Explorer y Google Chrome, la opción para deshabilitar las cookies se encuentra en la sección de “Privacidad” en las opciones de configuración de cada explorador.
<br/>
¿Cómo puede conocer los cambios a este Aviso de Privacidad?<br/>
El presente Aviso de Privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
<br/><br/>

Los cambios al Aviso de Privacidad se informarán a través de nuestro sitio web en la siguiente dirección: www.mascotalia.com.mx
      </Card>
    </Container>
    </>
  )
}
