import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

import { analytics } from "../../firebase/firebase";
import { logEvent } from "firebase/analytics";

export default function DogWalker({ show, handleClose, handleShow }) {
  return (
    <div id="dog-walker">
      <Container>
        <Row
          style={{ paddingTop: "40px", paddingBottom: "40px" }}
          className="align-items-center"
        >
          <Col md="8">
            <Row>
              <h1>¿Eres paseador o cuidador de perros?</h1>
              <h4>
                En Mascotalia tenemos un lugar para ti. <br />
                Inscríbete en nuestra plataforma y comienza a ganar dinero con
                nuevos clientes.
                <br />
              </h4>
            </Row>
            <Row>
              <h4 className="mt-4 bold mb-4">¿Qué necesito para registrarme?</h4>
              <Col xs="12" sm="4">
                <Image src="edad.png" className="dog-walker-icon" />
                <br />
                <span className="bold">Ser mayor de edad</span>
                <br />
                <span style={{ fontSize: "smaller" }}>
                  Debes tener al menos 18 años.
                </span>
              </Col>
              <Col xs="12" sm="4">
                <Image src="perfil.png" className="dog-walker-icon" />
                <br />
                <span className="bold">Tener foto de perfil</span>
                <br />
                <span style={{ fontSize: "smaller" }}>
                  Tu rostro se tiene que ver claro.
                </span>
              </Col>
              <Col xs="12" sm="4">
                <Image src="contacto.png" className="dog-walker-icon" />
                <br />
                <span className="bold">Información de contacto</span>
                <br />
                <span style={{ fontSize: "smaller" }}>
                  Tienes que tener WhatsApp y celular activo.
                </span>
              </Col>
            </Row>

            <Row>
              <Col xs="12" sm="4">
                <Image src="casa.png" className="dog-walker-icon" />
                <br />
                <span className="bold">Fotos de tu espacio</span>
                <br />
                <span style={{ fontSize: "smaller" }}>
                  En caso de ser cuidador de perros.
                </span>
              </Col>
              <Col xs="12" sm="4">
                <Image src="domicilio.png" className="dog-walker-icon" />
                <br />
                <span className="bold">Comprobante de comicilio</span>
                <br />
                <span style={{ fontSize: "smaller" }}>
                 Puede ser del agua, luz, teléfono, gas. Tiene coincidir con el domicilio que agregas.
                </span>
              </Col>
              <Col xs="12" sm="4">
                <Image src="identificacion.png" className="dog-walker-icon" />
                <br />
                <span className="bold">Identificación</span>
                <br />
                <span style={{ fontSize: "smaller" }}>
                 Puede ser INE, Pasaporte o licencia de conducir vigentes.
                </span>
              </Col>
            </Row>

            <a
              href="https://mascotalia.sharetribe.com"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                className="mt-3"
                variant="secondary"
                style={{ width: 300 }}
                onClick={() => {
                  logEvent(analytics, "dog_walker_btn");
                }}
              >
                Registrarme ahora
              </Button>
            </a>
          </Col>

          <Col md="4">
            <Image src="./dog_walker.png" className="img-fluid p-4" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
