import {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';

import {db, analytics} from '../../firebase/firebase';
import { collection, addDoc, setDoc, doc } from "firebase/firestore"; 
import { logEvent } from "firebase/analytics";




function ModalContact({show, handleClose, handleShow, formData}) {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    

   async  function sendData(e) {

        //   console.log(e.validateForm());
        console.log(email, name, phone);
        e.preventDefault()
        if(formData){
         console.log("Entro")
         formData.email = email;
         formData.name = name;
         formData.phone = phone;
         await setDoc(doc(db, "pet-owner-leads", formData.id), formData).then(()=>{
          handleClose();
          alert("Lo sentimos, todavía no estamos en tu zona. Espéranos muy pronto.")
          logEvent(analytics, 'complete_form', {formData})
         });
        }else{
          await addDoc(collection(db, "pet-owner-leads"), {email: email, name:name, phone:phone});
          handleClose();
          alert("Lo sentimos, todavía no estamos en tu zona. Espéranos muy pronto.")
          logEvent(analytics, 'personal_data', {email: email, name:name, phone:phone})
        }
          
        //handleClose();
    }
    return (
      <>
         <Modal show={show} onHide={handleClose}>
        <Form onSubmit={e=>sendData(e)}>
          <Modal.Header closeButton>
            <Modal.Title>Regálanos tus datos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Image src="./mascotalia_logo.png" className="center-block p-2" width="170px"/>
            <p className="text-center">Ingresa tus datos</p>
           <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Nombre</Form.Label>
                <Form.Control required type="text" placeholder="Nombre Completo" onChange={(e)=>setName(e.target.value)}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control required type="email" placeholder="hola@mascotalia.com" onChange={(e)=>setEmail(e.target.value)}/>
            </Form.Group>
           
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Teléfono Celular</Form.Label>
                <Form.Control pattern="[0-9]{10}" required type="text" minLength="10" maxLength="10" placeholder="55 55 55 55 55" onChange={(e)=>setPhone(e.target.value)}/>
            </Form.Group>
           
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit" >
             Enviar 
            </Button>
          </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
  
  export default ModalContact;