
import { Routes, Route } from "react-router-dom";
import LandingPetOwner from './pages/landing-pet-owner'
import LandingVets from './pages/landing-vets'
import Terms from './pages/terms'
import Privacy from './pages/privacy'

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<LandingPetOwner/>} >
      <Route path="/:version" element={<LandingPetOwner/>} />
      </Route>
      <Route path="/vets" element={<LandingVets/>} />
      <Route path="/terms" element={<Terms/>} />
      <Route path="/privacy" element={<Privacy/>} />
    </Routes>
  )
}
