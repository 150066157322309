import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Header({title}) {
  return (
    <Container fluid className="header-container" style={{ background: `url('${process.env.PUBLIC_URL}/header_img.png'), linear-gradient(168deg, #00A35E 0%, #18C27B 100%)`}}>
       <Container className="h-100">
       <Row className="align-items-center h-100 white-text">
        <Col xs="12" md="6" >
            <h1 >
                {title}
            </h1>
            <h5>
            Cuidar a tu mascota ahora es más facil.<br/>
            Busca entre los mejores prestadores de servicios para animales de tu área.
            </h5>
        </Col>
      </Row>

       </Container>
    </Container>
  )
}
