import React, {useState} from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import {db, analytics} from '../../firebase/firebase';
import { collection, addDoc } from "firebase/firestore"; 


export default function LandingForm({handleShow, handleClose, show, setFormData}) {

  const [state, setState] = useState("");
  const [petType, setPetType] = useState("");
  const [services, setServices] = useState([]);
  const arr = []

  const handleCheckboxChange = (value, checked) => {

    if(checked){
      arr.push(value)
    }else{
      let idx = arr.indexOf(value, 0);
      arr.splice(idx,1)
    }
    
  };

  
   
  async function getFormData(e) { 
    e.preventDefault()
    console.log(state, petType, arr)
    const fd = {
      state: state,
      petType: petType,
      services: arr
    }

    try {
      const docRef = await addDoc(collection(db, "pet-owner-leads"), fd);
      // console.log("Document written with ID: ", docRef.id);
      fd.id = docRef.id;
      setFormData(fd);
      handleShow();
     
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    
   

    //1 guardar en base de datos state petType y arr

    
    //2 pasar parametros a modal

   

  }

  return (
    <Container id="busca">
    <Card className="p-5" style={{ marginTop: "-60px" }}>
      <Form onSubmit={(e)=>getFormData(e)} validate="true">
        <Row>
          <Col md="6">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Estado</Form.Label>
              <Form.Select required onChange={(e)=> setState(e.target.value)}>
                <option value="no">Selecciona uno...</option>
                <option value="Aguascalientes">Aguascalientes</option>
                <option value="Baja California">Baja California</option>
                <option value="Baja California Sur">Baja California Sur</option>
                <option value="Campeche">Campeche</option>
                <option value="Chiapas">Chiapas</option>
                <option value="Chihuahua">Chihuahua</option>
                <option value="CDMX">Ciudad de México</option>
                <option value="Coahuila">Coahuila</option>
                <option value="Colima">Colima</option>
                <option value="Durango">Durango</option>
                <option value="Estado de México">Estado de México</option>
                <option value="Guanajuato">Guanajuato</option>
                <option value="Guerrero">Guerrero</option>
                <option value="Hidalgo">Hidalgo</option>
                <option value="Jalisco">Jalisco</option>
                <option value="Michoacán">Michoacán</option>
                <option value="Morelos">Morelos</option>
                <option value="Nayarit">Nayarit</option>
                <option value="Nuevo León">Nuevo León</option>
                <option value="Oaxaca">Oaxaca</option>
                <option value="Puebla">Puebla</option>
                <option value="Querétaro">Querétaro</option>
                <option value="Quintana Roo">Quintana Roo</option>
                <option value="San Luis Potosí">San Luis Potosí</option>
                <option value="Sinaloa">Sinaloa</option>
                <option value="Sonora">Sonora</option>
                <option value="Tabasco">Tabasco</option>
                <option value="Tamaulipas">Tamaulipas</option>
                <option value="Tlaxcala">Tlaxcala</option>
                <option value="Veracruz">Veracruz</option>
                <option value="Yucatán">Yucatán</option>
                <option value="Zacatecas">Zacatecas</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Tipo de mascota</Form.Label>
              <Form.Select required onChange={(e)=> setPetType(e.target.value)}>
                <option value="no">Selecciona...</option>
                <option value="Perro/Gato">Perro/Gato</option>
                <option value="Aves">Ave</option>
                <option value="Peces">Pez</option>
                <option value="Reptiles">Reptil</option>
                <option value="Animal Exótico">Animal Exótico</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md="6">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Especialidad/Servicio</Form.Label>

              <Row>
                <Col md="6">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="paseador_perros"
                    label="Paseador de perros"
                    value="paseador_perros"
                    onChange={(e) => handleCheckboxChange(e.target.value, e.target.checked)}
                  />
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="hotel_perros"
                    label="Hotel de perros"
                    value="hotel_perros"
                    onChange={(e) => handleCheckboxChange(e.target.value, e.target.checked)}
                  />
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="spa_perros"
                    label="Spa de perros"
                    value="spa_perros"
                    onChange={(e) => handleCheckboxChange(e.target.value, e.target.checked)}
                  />
                    <Form.Check // prettier-ignore
                    type="switch"
                    id="veterinaria"
                    label="Veterinaria"
                    value="veterinaria"
                    onChange={(e) => handleCheckboxChange(e.target.value, e.target.checked)}
                  />
                 
                </Col>

                <Col md="6">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="estética"
                    label="Estética"
                    value="estetica"
                    onChange={(e) => handleCheckboxChange(e.target.value, e.target.checked)}
                  />
                 

                  <Form.Check // prettier-ignore
                    type="switch"
                    id="vacunación"
                    label="Vacunación"
                    value="vacunacion"
                    onChange={(e) => handleCheckboxChange(e.target.value, e.target.checked)}
                  />
                   <Form.Check // prettier-ignore
                    type="switch"
                    id="entrenador_perros"
                    label="Entrenador de perros"
                    value="entrenador_perros"
                    onChange={(e) => handleCheckboxChange(e.target.value, e.target.checked)}
                  />
                
                </Col>
              </Row>
             
            </Form.Group>
          </Col>
         
        </Row>
        <Button variant="primary" className="ms-auto" width="200px" type="submit">Buscar ahora</Button>
      </Form>
    </Card>
    </Container>
  );
}
