import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import MyNavbar from "../components/landing-pet-owner/MyNavbar";
export default function Terms() {
  return (
    <>
      <MyNavbar />
      <Container>
        <Card className="p-4" style={{ marginTop: "90px" }}>
          
              <p  >
                ¡BIENVENIDO A Mascotalia!
              </p>
              <p  >
                Términos y Condiciones de Uso de la Plataforma y Prestación de
                los Servicios
              </p>
              <p  >
                Actualizados al día 13 de Marzo del 2020
              </p>
              <p  >
                POR FAVOR LEE CON ATENCIÓN LOS PRESENTES TÉRMINOS Y CONDICIONES
                (los "T&amp;C’s) ANTES DE UTILIZAR NUESTRO SITIO.
              </p>
              <p  >
                <strong>Prevención legal.</strong> Estos T&amp;C’s son
                obligatorios entre la persona ("<strong>Tú</strong>" o el "
                <strong>Usuario</strong>") que utiliza la plataforma, de
                conformidad con el alcance de este instrumento legal; y
                Layabit Finance, S.A.P.I. de C.V., ("<strong>Nosotros</strong>" o
                "<strong>Mascotalia</strong>"). Por lo tanto, este es un contrato
                vinculante y constituye el acuerdo total entre las Partes (el
                "Usuario" y "Mascotalia", conjuntamente) para el uso del sitio web,
                aplicaciones móviles y herramientas digitales propiedad de Mascotalia
                (la "<strong>Plataforma</strong>"), así como para la prestación
                de nuestros servicios.
              </p>
              <p  >
                Los presentes T&amp;C’s serán de aplicación para todos los
                Usuarios, ya sea que se trate de:
              </p>
              <p  >
                <ol>
                  <li>
                    Personas físicas que proveedores independientes de servicios
                    de cuidado de mascotas que desean ofertar sus servicios a
                    través de la Plataforma (en adelante, "
                    <strong>Usuarios Cuidadores</strong>"), o;
                  </li>
                  <li>
                    Personas físicas propietarias de mascotas que desean
                    contratar los servicios de los Usuarios Cuidadores (en
                    adelante, "<strong>Usuarios Propietarios</strong>").
                  </li>
                </ol>
              </p>
              <p>
                Lo anterior, sin perjuicio de aquellas estipulaciones que sean
                particularmente aplicables a cada uno de ellos.
              </p>
              <p  >
                Los términos "Usuario" y/o "Usuarios" incluyen tanto a Usuarios
                Propietarios como a Usuarios Cuidadores.
              </p>
              <p  >
                Por lo tanto, al ingresar, hacer uso de la Plataforma y/o crear
                una cuenta en la Plataforma ("<strong>Cuenta de Usuario</strong>
                ") , estás manifestando tu consentimiento a los presentes
                T&amp;C’s.
              </p>
              <p  >
                DECLARACIONES Y TÉRMINOS GENERALES
              </p>
              <p  >
                Para hacer uso de la Plataforma y obligarte de conformidad con
                los presentes T&amp;C’s debes de ser mayor de edad, en ninguna
                circunstancia, permitimos el acceso y/o utilización de la
                Plataforma a menores de 18 (Dieciocho) años.
              </p>
              <p  >
                Por nuestra parte, declaramos que: (i) somos una sociedad
                anónima promotora de inversión, de capital variable, debidamente
                constituida conforme las leyes de los Estados Unidos Mexicanos,
                (ii) la sociedad se encuentra inscrita en el Registro Federal de
                Contribuyentes (RFC) y al día en el cumplimiento de sus
                obligaciones fiscales.
              </p>
              <p  >
                Cualquier incumplimiento a estos T&amp;C’s nos faculta para dar
                por terminada cualquier relación con el Usuario sin necesidad de
                requerimiento judicial.
              </p>
              <p  >
                COMPROBACIÓN DE DATOS
              </p>
              <p  >
                Al ingresar en la Plataforma, usar y/o crear una Cuenta de
                Usuario en la Plataforma aceptas que Mascotalia puede verificar, por sí
                o por terceros, en cualquier momento los datos que nos
                proporciones. Para el caso en que la información proporcionada
                para la Cuenta de Usuario sea falsa o inexacta, Mascotalia podrá negar
                el acceso a la Plataforma, a su entera discreción, así como
                eliminar cualquier contenido relacionado con el Usuario. El
                Usuario acepta la verificación de sus datos, ya sea por Mascotalia, o a
                través de un tercero que nosotros designemos.
              </p>
              <p  >
                MODIFICACIONES Y ACTUALIZACIONES
              </p>
              <p  >
                Nos reservamos el derecho de: (i) adicionar o modificar estos
                T&amp;C’s en cualquier momento, así como (ii) ofertar nuevos
                servicios a través de la Plataforma. Por favor permanece
                pendiente de los presentes T&amp;C’s.
              </p>
              <p  >
                Los nuevos T&amp;C’s le serán notificados al Usuario por medio
                de correo electrónico y estarán disponibles en el sitio web
                <a href="https://www.mascotalia.com.mx/terms"> https://www.mascotalia.com.mx/terms </a>, en el entendido
                de que dichas modificaciones se entenderán aceptadas por el
                Usuario una vez entradas en vigor las modificaciones en la
                Plataforma.
              </p>
              <p  >
                Cualquier modificación que realicemos a estos T&amp;C’s
                aplicarán a cualquier sitio web, aplicación móvil y/o cualquier
                otra herramienta o tecnología de la cual Mascotalia sea titular.
                Asimismo, dichas modificaciones surtirán efectos en el momento
                en el que estos T&amp;C’s sean actualizados.
              </p>
              <p  >
                PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES
              </p>
              <p  >
                Los Datos Personales que de los Usuarios de la Plataforma sean
                recabados por Mascotalia, serán tratados de conformidad con lo
                establecido en el Aviso de Privacidad que se encuentra contenido
                en el sitio web, aplicaciones móviles, herramientas digitales y
                oficinas de Mascotalia.
              </p>
              <p  >
                Para obtener información correspondiente a la recaudación,
                registro, conservación, modificación, consulta, utilización,
                comunicación por transferencia, difusión o cualquier otro tipo
                de habilitación de acceso, cotejo o interconexión, limitación,
                supresión o destrucción de tus datos personales, deberás
                consultar nuestro Aviso de Privacidad, disponible en la
                siguiente liga
                <a href=" https://www.mascotalia.com.mx/aviso-privacidad" >
                  https://www.mascotalia.com.mx/aviso-privacidad{" "}
                </a>
              </p>
              <p  >
                REGISTRO DE CUENTA, AUTENTICACIÓN Y SEGURIDAD DE DATOS
              </p>
              <p  >
                Para acceder a la Plataforma, el Usuario deberá: (i) registrarse
                en la Plataforma completando todos los campos del formulario de
                inscripción con datos válidos o (ii) iniciar sesión a través de
                Facebook, así como aceptar los presentes T&amp;C’s y crear la
                Cuenta de Usuario, ya sea como Usuario Propietario o Usuario
                Cuidador.
              </p>
              <p  >
                En el caso de los Usuarios Cuidadores, previo a la creación de
                la Cuenta, deberán enviar un formulario de admisión (en
                adelante, "<strong>Formulario de Admisión de Cuidadores</strong>
                ") completo con sus datos, para poder brindar sus servicios en
                la Plataforma. Mascotalia evaluará la información brindada, y
                eventualmente aceptará o rechazará la solicitud. Una vez
                aceptada la solicitud, Mascotalia dará de alta la Cuenta de Usuario
                Cuidador, y éste podrá comenzar a ofrecer sus servicios a través
                de la Plataforma. Mascotalia se reserva los derechos de rechazar
                solicitudes que no cumplan con su criterio. El uso de nombres
                comerciales o compartir datos personales de contacto en las
                solicitudes está prohibido.
              </p>
              <p  >
                Los Usuarios Cuidadores, para poder ofrecer sus servicios a
                través de la Plataforma, autorizan a Mascotalia a publicar en la
                Plataforma todos los datos de su Cuenta de Usuario Cuidador,
                información y fotografías, a los efectos de poner tales datos a
                disposición de los Usuarios Propietarios para que contraten sus
                servicios.
              </p>
              <p  >
                Mascotalia no se responsabiliza por la certeza o veracidad de los datos
                personales que los Usuarios pongan a su disposición. Los
                Usuarios garantizan y responden, en cualquier caso, de la
                veracidad, exactitud, vigencia y autenticidad de los Datos
                Personales puestos a disposición de Mascotalia, no obstante Mascotalia podrá
                dar de baja las cuentas de Usuarios que tengan indicios de
                falsedad sin responsabilidad alguna.
              </p>
              <p  >
                Como elementos de autenticación, el Usuario contará con: a)
                Cuenta de Usuario, b) Contraseña ("Contraseña" o "Password") o
                c) inicio de sesión a través de Facebook.
              </p>
              <p  >
                Por lo que se refiere a las Contraseñas temporales que le serán
                proporcionadas al Usuario al crear la Cuenta de Usuario, el
                Usuario se obliga a cambiar las mismas, la primera vez que
                ingrese a la Plataforma. De esta manera, al tener nueva
                contraseña creada por el Usuario, Mascotalia se deslinda de cualquier
                responsabilidad por el uso inadecuado de las mismas, toda vez
                que desconocen las Contraseñas. Para el cambio de Contraseñas,
                el Usuario deberá seguir los procedimientos que se señalen en la
                Plataforma.
              </p>
              <p  >
                Mascotalia cuenta con un sistema de seguridad SSL y algoritmo de
                cifrado AES- 256 para la protección de la información que
                compartes con nosotros. Por lo anterior, nos comprometemos a
                utilizar tus datos únicamente en la medida que lo hayas
                autorizado y de conformidad con lo dispuesto en los presentes
                T&amp;C’s.
              </p>
              <p  >
                El Usuario manifiesta su consentimiento y se obliga a aplicar y
                mantener vigentes todos los mecanismos de seguridad sugeridos
                por Mascotalia en los presentes T&amp;C’s que de tiempo en tiempo estén
                vigentes o le sean dadas a conocer por Mascotalia.
              </p>
              <p  >
                LICENCIA
              </p>
              <p  >
                En virtud del presente Contrato, Mascotalia concede a favor del
                Usuario, una licencia temporal, no exclusiva, revocable y
                limitada respecto de la Plataforma, para que este último la
                utilice, por sí y para sí mismo, de acuerdo a presentes
                T&amp;C’s, en atención a las especificaciones generales y
                técnicas que se establecen de manera enunciativa más no
                limitativa en la sección siguiente y en el Contrato.
              </p>
              <p  >
                CARACTERÍSTICAS DE LA PLATAFORMA
              </p>
              <p  >
                La Plataforma estará disponible para el Usuario pudiendo estar
                asociado al uso de un sitio web o aplicación móvil. El Usuario
                deberá ingresar a la Cuenta de Usuario con su contraseña.
              </p>
              <p  >
                Para fines de claridad la Plataforma consta de las siguientes
                funcionalidades: (i) Hospedaje, (ii) Guardería de día, (iii)
                Paseo, (iv) Visitas a cada, (v) Orientación Veterinaria, (vi)
                Solicitudes, (vii) Tus Mascotas, y (viii) Cuenta de Usuario.
              </p>
              <p  >
                FUNCIONAMIENTO DE LA PLATAFORMA
              </p>
              <p  >
                Podrás hacer uso de la Plataforma con la finalidad de
                proporcionar o contratar los siguientes servicios para animales
                domésticos: (i) Hospedaje, (ii) Paseo Tradicional, , (iii)
                Guardería de día (Hospedaje de 12 horas), (iv) Visitas a casa,
                (los "Servicios").
              </p>
              <p  >
                Para fines de claridad los Servicios son prestados por terceros
                independientes "Usuarios Cuidadores", y, en este sentido Mascotalia
                consiste en una plataforma online, que facilita el acercamiento
                y contacto entre Usuarios Propietarios y Usuarios Cuidadores.
              </p>
              <p  >
                PROHIBICIONES AL USUARIO Y CANCELACIÓN DE CUENTA
              </p>
              <p  >
                El Usuario en ningún caso podrá:
              </p>
              <p  >
                <ol>
                  <li>
                    Utilizar, como nombre de Usuario, el nombre de otra persona.
                  </li>
                  <li>
                    Utilizar, como nombre de Usuario, un nombre ofensivo, vulgar
                    u obsceno.
                  </li>
                  <li>
                    Utilizar, como nombre de Usuario, un nombre sujeto a
                    derechos de otra persona sin contar con la debida
                    autorización de ésta.
                  </li>
                  <li>
                    Utilizar, sin la debida autorización, la Cuenta de otro
                    Usuario.
                  </li>
                  <li>
                    Utilizar, sin la debida autorización, información de otro
                    Usuario como propia.
                  </li>
                  <li>
                    Utilizar la Plataforma o los Servicios del de la Plataforma
                    para contratar o solicitar a otros Usuarios cuestiones
                    diferentes de las previstas en la Plataforma y en estos
                    T&amp;C’s.
                  </li>
                </ol>
              </p>
              <p  >
                El Usuario podrá dar de baja la Cuenta de Usuario mediante la
                Plataforma<a href="www.mascotalia.com.mx"> www.mascotalia.com.mx </a>
              </p>
              <p>
                Mascotalia se reserva el derecho de cerrar, editar y/o cancelar la
                Cuenta de Usuario si éste no cumple con las prohibiciones
                mencionadas o incluso sin una razón particular.
              </p>
              <p  >
                <strong>
                  Aceptar reservas y pagos directos en efectivo de Usuarios
                  Propietarios obtenidos por Mascotalia es una violación directa a los
                  presentes T&amp;C’s por lo cual resultará en la baja absoluta
                  de la cuenta de Usuarios Propietarios y Usuarios Cuidadores.
                </strong>{" "}
                Además de contar con el respaldo de una empresa, nuestra
                plataforma de pago fue diseñada para proteger la información de
                la tarjeta de crédito o débito. Su información personal nunca se
                comparte con su cuidador. No obstante, ofrecemos otros métodos
                de pagos como en oxxo, seven y algunos bancos. Pagar fuera del
                sistema de mascotalia puede ponerlo en riesgo de fraude.
              </p>
              <p  >
                CONDICIONES GENERALES DEL SERVICIO
              </p>
              <p  >
                Para fines de claridad los Servicios prestados por Usuarios
                Cuidadores no son prestados por Mascotalia. No obstante, Mascotalia coadyuvará
                en todo momento en caso de que exista alguna duda, comentario o
                requieras de nuestra ayuda; en tal caso deberás de enviar tu
                solicitud en la Sección Ayuda que se encuentra dentro de la
                Plataforma.
              </p>
              <p  >
                Los Usuarios Propietarios, para solicitar los servicios de: (i)
                Hospedaje, (ii) Paseo On Demand (POD), (iii) Guardería de día
                (Hospedaje de 12 horas) o (iv) Paseo Tradicional deberán de:
              </p>
              <p  >
                <ol>
                  <li>
                    ingresar a la Plataforma, autorizar el acceso a tu ubicación
                    y seleccionar las fechas en las que deseas solicitar el
                    servicio;
                  </li>
                  <li>
                    se desplegarán los datos de los Usuarios Cuidadores
                    disponibles junto con los datos del Perfil del Usuario
                    Cuidador, incluyendo el precio de sus servicios;
                  </li>
                  <li>
                    en Mascotalia, sabemos lo importante que es tu mascota, por eso
                    antes de contratar un servicio de un Usuario Cuidador,
                    podrás comunicarte con él/ella a través del chat
                    correspondiente.
                  </li>
                  <li>
                    al solicitar el servicio deberás agregar un horario de
                    entrega y recolección.
                  </li>
                  <li>
                    por último deberás solicitar el método de pago, ya sea cargo
                    automático a tu tarjeta de crédito o débito o pago en
                    establecimientos indicados en la plataforma.
                  </li>
                  <li>
                    para contratar un servicio deberás realizar tu pago conforme
                    la Sección{" "}
                    <strong>PRECIOS, FORMAS DE PAGO Y REEMBOLSOS</strong>.
                  </li>
                </ol>
              </p>
              <p  >
                En este sentido, los Usuarios, al aceptar estos T&amp;C’s
                aceptan su conformidad con compartir los datos de su Perfil con
                otros Usuarios de la Plataforma. No obstante, Mascotalia en todo
                momento apoyará a los Usuarios cuando el uso de estos datos sea
                utilizado con fines distintos a lo aquí dispuesto y se reserva
                el derecho de: (i) dar la Cuenta de Usuario de baja o (ii)
                coadyuvar con las autoridades en caso de que sea requerido.
              </p>
              <p  >
                GARANTÍAS
              </p>
              <p>
                Mascotalia procurará el buen y debido funcionamiento de la Plataforma
                siempre y cuando se conduzca sobre los parámetros, capacidad
                operativa y sistemas de cómputo debidos, incluyendo la adecuada
                conexión a Internet. En tal virtud, las Partes expresamente
                acuerdan que cualquier mal funcionamiento de la Plataforma o
                cualquier falla generada por causas directamente imputables al
                Usuario o a los proveedores de servicios de Internet, no serán
                responsabilidad de Mascotalia y, por tanto, no serán cubiertas por Mascotalia.
              </p>
              <p  >
                SOPORTE TÉCNICO
              </p>
              <p  >
                Mascotalia se compromete a brindar el soporte técnico y asesoría al
                Usuario sin costo alguno, para éste pueda hacer uso de la
                Plataforma, durante la vigencia del Contrato. Asimismo, Mascotalia se
                compromete a atender cualquier falla técnica que impida o
                reduzca el uso de la Plataforma. En tal sentido, Mascotalia se
                compromete a dar respuesta a cualquier consulta o reporte de
                falla, en plazo máximo dentro de los 5 (Cinco) días hábiles
                siguientes a que el reporte sea presentado a la dirección de
                correo electrónicocontacto@mascotalia.com.mx. En dicho reporte
                deberá constar la fecha y hora, descripción de la falla, nombre,
                correo electrónico y teléfono de la persona que reporta la
                falla. Mascotalia contará con un plazo máximo de 15(Quince) días
                hábiles para corregir y reparar cualquier tipo de error o falla
                en la Plataforma. En este caso, Mascotalia se compromete a enviar al
                Usuario un reporte acerca de la reparación realizada.
              </p>
              <p  >
                INFORMACIÓN CONFIDENCIAL
              </p>
              <p  >
                El usuario se obliga a proteger y guardar como confidencial y a
                no divulgar a tercero alguno ninguna Información Confidencial
                (según dicho término se define más adelante) recibida de Mascotalia u
                obtenida en la Plataforma, sus sociedades relacionadas, agentes,
                representantes o de cualquier otra forma descubierta por el
                Usuario en relación con o con motivo del uso de la Plataforma
                y/o la Cuenta de Usuario, según sea el caso.
              </p>
              <p  >
                Para efectos de estos T&amp;C’s se entenderá por "Información
                Confidencial" toda aquella información que no sea de
                conocimiento público y que es o sea usada, desarrollada u
                obtenida por Mascotalia y/o sus sociedades relacionadas, agentes,
                representantes o de cualquier otra forma, incluyendo sin
                limitar: (i) informaciones, procedimientos y datos obtenidos y/o
                desarrollados por Mascotalia o sus sociedades relacionadas,
                concernientes o relacionadas a negocios o asuntos de Mascotalia o sus
                sociedades relacionadas; (ii) productos o servicios; (iii)
                costos y estructuras de precios; (iv) análisis; (v) métodos de
                negocios y de contabilidad; (vi) toda información escrita,
                gráfica, electromagnética, incluyendo sin limitación alguna, la
                información técnica, códigos fuente, documentación y demás
                artefactos producidos y desarrollados por Mascotalia; (vii) software de
                computación, incluyendo sistemas operativos, solicitudes y
                listados de programación; (viii) organigramas, manuales y
                documentación; (ix) todos los métodos de producción, procesos,
                tecnología y secretos comerciales y proyectos de mercado; (x)
                nombres de los accionistas, socios actuales o de inversión; y
                (xi) toda otra información similar y relacionada, en cualquier
                forma.
              </p>
              <p  >
                Asimismo, el Usuario se obliga a utilizar la Información
                Confidencial sólo para los efectos del cumplimiento de sus
                obligaciones bajo estos T&amp;C’s. A fin de mantener dicho marco
                de confidencialidad, el Usuario se compromete a tomar las
                medidas de seguridad que consideren necesarias y razonables y
                que incluyan, como mínimo, aquellas que utilice para proteger su
                propia información confidencial. El Usuario deberá adoptar las
                medidas técnicas y organizativas que resulten necesarias para
                garantizar la seguridad y confidencialidad de la Información
                Confidencial, de modo de evitar su modificación, adulteración,
                pérdida, consulta o tratamiento no autorizado, y que permitan
                detectar desviaciones, intencionales o no, de Información
                Confidencial, ya sea que los riesgos provengan de la acción
                humana o del medio técnico utilizado.
              </p>
              <p  >
                El Usuario podrá divulgar dicha Información Confidencial,
                únicamente y exclusivamente al personal que necesite conocer
                dicha información para cumplir con sus obligaciones conforme a
                lo establecido en estos T&amp;C’s. Independientemente de lo
                señalado anteriormente, el Usuario seguirá siendo responsable
                por el uso que ellas y su personal hagan de la Información
                Confidencial, debiendo responder frente a Mascotalia de cualquier daño
                o perjuicio que pudiera generarse con motivo de la divulgación
                de la misma. Asimismo, el Usuario declara conocer las penas en
                que incurre una persona por revelación de secretos, de acuerdo
                con lo establecido en el artículo 213 del Código Penal para el
                Distrito Federal.
              </p>
              <p  >
                El Usuario se obliga a respetar las disposiciones contenidas en
                la presente sección, aún después de la conclusión de la relación
                contractual o de cualquier naturaleza con Mascotalia, quedando
                subsistente su obligación de no revelar la información que haya
                llegado a conocer, por un lapso de 10 (diez) años contados a
                partir de la terminación de la relación contractual o de
                cualquier naturaleza con Mascotalia.
              </p>
              <p  >
                INDEPENDENCIA DE LAS PARTES
              </p>
              <p  >
                Mascotalia es una empresa independiente, tanto de Usuarios Cuidadores
                como de Usuarios Propietarios, no pudiendo interpretarse el
                presente como un contrato de sociedad, mandato, agencia,
                relación laboral, locación de servicios ni de cualquier otro
                tipo entre Mascotalia y los Usuarios.
              </p>
              <p  >
                PRECIOS, FORMAS DE PAGO Y REEMBOLSOS
              </p>
              <p  >
                Al solicitar un servicio te proporcionaremos una cotización,
                basada en el precio estipulado por el Usuario Cuidador y el
                número de servicios que hayas solicitado más una tarifa de
                servicio Mascotalia no reembolsable (en adelante "Tarifa de Servicio
                Mascotalia"). El monto de la Tarifa de Servicio Mascotalia puede variar
                dependiendo del subtotal de cada reserva, estableciéndose como
                mínimo $11.00 (Once Pesos 00/100 Moneda Nacional) y un máximo
                $132.00 (Ciento Treinta y Dos Pesos 00/100 Moneda Nacional ) por
                reserva. La Tarifa de Servicio Mascotalia no es reembolsable bajo
                ningún caso de cancelación por parte del Usuario Propietario.
              </p>
              <p  >
                El Usuario Propietario deberá pagar tarifas adicionales cuando:
                a) b) Se excedan 12 (Doce Horas) en la hora de recolección para
                el servicio Guardería de día; c) retrasos de más de 1 (Una) hora
                en la recolección de los Servicios y Paseo Tradicional (las{" "}
                <strong>"Tarifas Adicionales"</strong>). Las Tarifas Adicionales
                se calculan utilizando un factor del precio unitario de Cada
                Usuario Cuidador, es decir, cada el precio se prorrateará de
                conformidad con las horas adicionales y el precio estipulado por
                cada Usuario Cuidador.
              </p>
              <p  >
                No obstante lo anterior el Usuario podrá cancelar:
              </p>
              <p  >
                <ol>
                  <li>
                    El usuario tiene hasta 48 horas antes del comienzo de la
                    reserva para cancelar su reserva en la cual será
                    reembolsable el 100% del subtotal de la reserva. La Tarifa
                    de Servicio no es reembolsable.
                  </li>
                  <li>
                    Las cancelaciones con menos de 24 horas de anticipación o
                    después de que comience la reserva, tendrán un reembolso del
                    50% por los primeros 7 días y 100% por los días adicionales.
                  </li>
                  <li>
                    Si recoges a tu mascota antes de que termine la reserva, se
                    realiza un reembolso del 50% de los días restantes de la
                    reserva siempre y cuando el cambio se pida en el mismo día
                    del comienzo de la reserva o durante la reserva. En caso de
                    que el cambio se pida 24 horas antes del comienzo el
                    reembolso será del 100%.
                  </li>
                  <li>
                    Si tu cuidador cancela la reserva, recibirás un reembolso
                    completo.
                  </li>
                </ol>
              </p>
              <p  >
                *Los reembolsos excluyen la tarifa de servicio, la cual solo es
                reembolsable cuando el cuidador cancela la reserva.
              </p>
              <p  >
                El Usuario Propietario que desee contratar los servicios de un
                Usuario Cuidador deberá realizar una reserva en línea, abonando
                el importe total de la reserva mediante alguno de los medios de
                pago disponibles en la Plataforma (Tarjeta de crédito, tarjeta
                de débito, depósito en efectivo, depósito o transferencia
                bancaria).
              </p>
              <p  >
                Los Usuarios Propietarios pueden efectuar una reserva y los
                Usuarios Cuidadores tienen hasta 24 (Veinticuatro) horas para
                confirmar la misma. Si una reserva es cancelada por el Usuario
                Cuidador se reembolsará el subtotal de la reserva incluyendo la
                Tarifa de Servicio.
              </p>
              <p>
                Mascotalia sólo admite los medios de pago que aparecen identificados en
                la Plataforma al momento de la contratación. A los efectos de
                facilitar los datos de pago el Usuario Propietario deberá seguir
                el procedimiento que en cada momento se establezca en la
                Plataforma.
              </p>
              <p  >
                Los Usuarios Cuidadores establecerán de manera independiente los
                precios de los servicios que ofrecen, los cuales siempre
                incluirán todos los impuestos que resulten legalmente exigibles.
              </p>
              <p  >
                Mascotalia establece los siguientes mínimos para los servicios
                establecidos: Hospedaje (es una estancia de hasta 24
                (Veinticuatro) horas): (i) $200.00 M.N. (Doscientos Pesos 00/100
                Moneda Nacional) por noche, (ii) Guardería Diurna $150.00 M.N.
                (Ciento Cincuenta Pesos 00/100 Moneda Nacional), (iii) Paseo
                Tradicional $80.00 M.N. (Ochenta Pesos 00/100 Moneda Nacional) y
                Visitas a casa $120.00 M.N.(Ciento Veinte Pesos 00/100 Moneda
                Nacional).
              </p>
              <p  >
                Mascotalia se reserva la facultad discrecional de cancelar
                reservaciones que no cumplan con nuestros requisitos de mínimos
                de seguridad y precio conforme a los presentes T&amp;C’s. En el
                caso que Mascotalia cancele una reserva se reembolsará el 100% (Cien
                Por Ciento) del subtotal de la reserva incluyendo la Tarifa de
                Servicio Mascotalia.
              </p>
              <p  >
                En este mismo acto, el Usuario Cuidador confiere a Mascotalia, y esta
                acepta para sí una comisión mercantil con la finalidad de
                promocionar sus servicios a través de la Plataforma a cambio de
                una contraprestación del 25% (Veinticinco Por Ciento) (la{" "}
                <strong>"Comisión Mascotalia"</strong>). Por lo que, como Usuario
                Cuidador{" "}
                <strong>
                  aceptas tu conformidad con no contactar a los Usuarios
                  Propietarios por medios distintos a la Plataforma
                </strong>
                .
              </p>
              <p  >
                Por lo anterior el Usuario acepta que el pago por los servicios
                se realizará a Mascotalia, quien a su vez se compromete a pagar al
                Usuario Cuidador la cantidad que resulte del valor de la reserva
                menos gastos por servicios e impuestos y otros recargos
                aplicables y la Comisión Mascotalia a más tardar 5 (Cinco) días hábiles
                de haberse llevado a cabo el servicio, mediante transferencia de
                fondos inmediatamente disponibles a la cuenta que el Usuario
                Cuidador indique o a través del sitio mercadopago.com.
              </p>
              <p  >
                El Usuario Cuidador debe proporcionar los datos bancarios donde
                se realizará la transferencia y es responsabilidad de éste la
                corrección y exactitud de los mismos. Mascotalia no se hace responsable
                de la llegada de los fondos una vez se haya enviado el dinero a
                la cuenta proporcionada por el Usuario Cuidador.
              </p>
              <p  >
                Mascotalia no es el prestador de los servicios contratados por los
                Usuarios Propietarios por lo que, en todo caso a los efectos de
                obtener las oportunas facturas o tickets sustitutivos, de
                conformidad con lo establecido en la legislación vigente, deberá
                dirigirse al Usuario Cuidador quien, como profesional encargado
                de la prestación de los servicios, será el obligado a expedir
                los documentos anteriormente indicados. Es decir, el Usuario
                Cuidador entregará al Usuario Propietario el comprobante
                correspondiente por el pago realizado, siendo el único
                responsable del cumplimiento de sus obligaciones fiscales al
                respecto.
              </p>
              <p  >
                Excepto por los impuestos sobre los ingresos y los ingresos
                brutos de Mascotalia en general, el Usuario Cuidador reconoce que es
                responsable de pagar cualquier impuesto que surja como resultado
                de su provisión o uso de servicios a través del Servicio de Mascotalia.
                Esto incluye, sin limitación, cualquier forma de impuesto a las
                ventas o impuesto sobre la renta sobre los ingresos del Usuario
                Cuidador recibidos a través del Servicio de Mascotalia.
              </p>
              <p  >
                PROGRAMA DE COBERTURA
              </p>
              <p  >
                Todas las contrataciones de los servicios de Usuarios Cuidadores
                realizadas mediante la Plataforma incluyen un Programa de
                Cobertura (en adelante, "<strong>Programa de Cobertura</strong>
                "). Nuestro Programa de Cobertura ofrece cobertura en los
                siguientes y sólo en los puntos mencionados y por dos eventos al
                año:
              </p>
              <p  >
                <ol>
                  <li>
                    Daños ocasionados por la mascota hacia algún bien mueble o a
                    la casa del cuidador: Límites máximos de responsabilidad:
                    $4,000.00 (Cuatro Mil Pesos 00/100 Moneda Nacional) por
                    evento. Deducible: 15% (Quince Por Ciento) sobre el monto de
                    la pérdida.
                  </li>
                  <li>
                    Daños ocasionados por la mascota al cuidador o algún tercero
                    (mordida, etc): Límites máximos de responsabilidad:
                    $4,000.00 (Cuatro Mil Pesos 00/100 Moneda Nacional) por
                    evento. Deducible: 15% (Quince Por Ciento) sobre el monto de
                    la pérdida.
                  </li>
                  <li>
                    Reembolso por enfermedad de la mascota: Límites máximos de
                    responsabilidad: $5,000.00 (Cinco Mil Pesos 00/100 Moneda
                    Nacional) por evento. Deducible: 15% (Quince Por Ciento)
                    sobre el monto de la pérdida.
                  </li>
                  <li>
                    Muerte de la mascota: Límites máximos de responsabilidad:
                    $5,000.00 (Cinco Mil Pesos 00/100 Moneda Nacional) por
                    evento. Deducible: 15% (Quince Por Ciento) sobre el monto de
                    la pérdida.
                  </li>
                  <li>
                    Cláusula bajo convenio expreso se ampara el extravío de
                    mascotas hasta un sublímite de 10,000.00 M.N. (Diez Mil
                    Pesos 00/100 Moneda Nacional) por evento, dicha suma
                    asegurada será utilizada para: publicidad, folletos,
                    anuncios o actividades relacionadas con gastos para
                    recuperar la mascota.
                  </li>
                  <li>
                    El Programa de Cobertura es válido únicamente para reservas
                    pagadas y confirmadas a través de nuestra Plataforma.
                  </li>
                </ol>
              </p>
              <p  >
                Mascotalia no es aseguradora ni agente de seguros, asimismo, se reserva
                el derecho de modificar o terminar el Programa de Cobertura en
                cualquier momento, a su entera discreción y sin previo aviso. Si
                Mascotalia termina el Programa de Cobertura de acuerdo con lo anterior,
                Mascotalia le proporcionará un aviso de dicha terminación y Mascotalia
                continuará procesando reclamaciones bajo el Programa de
                Cobertura presentado antes de la fecha efectiva de terminación.
                Si Mascotalia modifica el Programa de Cobertura, publicaremos la
                modificación en la Plataforma o le notificaremos de la
                modificación. Al continuar accediendo a su Cuenta de Usuario o
                utilizando la Plataforma después de haber publicado una
                modificación, el Usuario está indicando que acepta las
                modificaciones del Programa de Cobertura. Si el Programa de
                Cobertura modificado no es aceptable para el Usuario, este debe
                de dejar de usar la Plataforma. El Programa de Cobertura se
                encuentra sujeto a las limitaciones, exclusiones y condiciones
                descritas en los siguientes puntos
              </p>
              <p  >
                Los Usuarios que requieran el Reembolso por enfermedad de la
                mascota (en adelante cobertura veterinaria), mencionada en la
                cobertura en el punto 3 (Tres) de la presente Sección deberán
                seguir las instrucciones proporcionadas por Mascotalia, incluyendo el
                llenado de cualquier formulario proporcionado por Mascotalia. Mascotalia no
                extenderá la cobertura cuando la factura correspondiente no sea
                expedida a nombre de Mascotalia, así como el comprobante impreso desde
                la Plataforma al momento de la contratación de los Servicios. La
                Cobertura Veterinaria no se aplica a aquellos Usuarios que
                cuenten con un seguro vigente y válido que cubra las
                enfermedades en cuestión.
              </p>
              <p  >
                Mascotalia no se hace responsable en caso de abandono de mascota
                después de que concluya algún servicio solicitado en la
                plataforma en el domicilio del usuario cuidador, sin embargo, se
                le apoyará al usuario cuidador con hasta $1,000.00 MN ( Un Mil
                Pesos 00/100 Moneda Nacional) para la promoción de adopción de
                la mascota, ya sea para que el usuario cuidador pueda cubrir
                diferentes gastos como eventos, traslado, alimento, etc. el
                mismo se aplicará por medio de reembolso con comprobantes
                membretados.
              </p>
              <p  >
                La Cobertura Veterinaria sólo cubre lesiones de emergencia o
                medicamentos prescritos durante la visita veterinaria y hasta
                una placa que se necesite exclusivamente durante el periodo de
                los Servicios.
              </p>
              <p>
                La Cobertura Veterinaria no abarca aquellas prestaciones que
                tengan lugar como consecuencia de: a) enfermedades o lesiones de
                la mascota recurrentes o preexistentes a la contratación de los
                Servicios del Usuario Cuidador; b) cirugías o intervenciones
                programadas por patologías preexistentes; c) enfermedades
                infecciosas que puedan sufrir las mascotas que no cuenten con el
                carnet de vacunación al día; d) eventualidades veterinarias de
                cualquier tipo que se deban a la vejez o raza del animal; e)
                medicamentos que le sean suministrados habitualmente a la
                mascota con anterioridad a la contratación de los servicios del
                Usuario Cuidador.
              </p>
              <p  >
                Para ser elegible por la cobertura veterinaria de emergencias,
                el Usuario debe haber reservado el hospedaje de su mascota a
                través de la Plataforma, informar a Mascotalia dentro de 24
                (Veinticuatro) horas desde que haya ocurrido la lesión o
                emergencia, y aportar a Mascotalia toda la documentación veterinaria
                oficial y certificada por un veterinario colegiado destacando el
                hecho de que dicha lesión no está por ninguna circunstancia
                relacionada con enfermedades o lesiones preexistentes.
              </p>
              <p  >
                Mascotalia podrá, a su discreción, ponerse en contacto con el
                veterinario para validar la información y llevar a cabo
                controles esporádicos.
              </p>
              <p  >
                Mascotalia se reserva el derecho de pedir informacional adicional a las
                coberturas de los puntos 1 y 2 de la presente Sección, tales
                como: (i) evidencia aplicable (fotos, bienes dañados, etc.)
                factura o notas, carta de reclamación y otros dependiendo del
                caso de la reclamación.
              </p>
              <p  >
                Las reclamaciones deben de ser realizadas exclusivamente por los
                Usuarios Cuidadores y deben de realizarse al menos 24 hrs
                despues de la salida de su mascota, siendo esta la hora que
                marca la reserva.
              </p>
              <p  >
                Una vez entregado todos los documentos que el Programa de
                Cobertura requiera, el reembolso o encaso solución al reclamo
                presentado puede tardar hasta mínimo 5 días hábiles en obtener
                respuesta, mas no se limita a este tiempo específico.
              </p>
              <p  >
                PROPIEDAD INTELECTUAL
              </p>
              <p  >
                La Plataforma <a href="www.mascotalia.com.mx"> www.mascotalia.com.mx </a>, así como su
                contenido es de propiedad exclusiva de Mascotalia.
              </p>
              <p  >
                El Usuario se compromete a cumplir y respetar todos los derechos
                de propiedad que Mascotalia posee sobre la Plataforma, y sobre el
                contenido del mismo. Queda prohibida su reproducción total o
                parcial, incluso para uso personal, por cualquier medio y bajo
                cualquier forma, permanente o transitoria; la traducción,
                adaptación, arreglo o cualquier otra transformación y la
                reproducción de los resultados de tales actos, y cualquier forma
                de distribución pública. Estos actos, realizados sin
                autorización de Mascotalia, constituyen una infracción de sus derechos
                de explotación y pueden constituir un delito contra la propiedad
                intelectual de acuerdo al Código Penal de los Estados Unidos
                Mexicanos.
              </p>
              <p  >
                La marca mascotalia y/o derivados son de exclusiva propiedad
                de Mascotalia. El Usuario deberá comunicar a Mascotalia cualquier uso indebido
                de las marcas comerciales que lleguen a su conocimiento y deberá
                asistir a Mascotalia en todas las medidas que sean necesarias para
                defender los derechos de propiedad intelectual sobre sus marcas.
              </p>
              <p  >
                CONTENIDO DEL USUARIO
              </p>
              <p  >
                La Plataforma otorga la posibilidad de que, según lo acuerden
                entre el Usuario Cuidador y el Usuario Propietario, el Usuario
                Cuidador suba diaria o periódicamente fotografías de la mascota
                a su cuidado, permitiendo de este modo al Usuario Propietario
                realizar un seguimiento del estado de la mascota.
              </p>
              <p  >
                El Usuario Propietario deberá autorizar previamente al Usuario
                Cuidador a los efectos de llevar a cabo la subida de estos
                contenidos. En este sentido, el Usuario Cuidador y/o el Usuario
                Propietario serán los únicos responsables de todos los daños y
                perjuicios que pudiera causar la utilización de su perfil, así
                como la subida de contenidos, información, datos, incluido, sin
                que se entienda con carácter limitativo, la vulneración de
                derechos de propiedad intelectual, industrial, derechos al
                honor, intimidad y propia imagen. De esta forma el usuario,
                mantendrá indemne a Mascotalia de cualquier reclamación y/o sanción que
                pudieran imponerle a causa de los contenidos incluidos por el
                usuario en el Servicio.
              </p>
              <p  >
                Los Usuarios no podrán utilizar los espacios que se habilitan en
                la Plataforma para incluir información con carácter obsceno,
                abusivo, o que constituya un supuesto de amenaza, injuria o
                calumnia, atentado al honor, intimidad o imagen de las personas
                o invada la privacidad de terceros, infrinja derechos de
                propiedad intelectual o de cualquier otra forma resulte ofensivo
                para terceros o censurable lesivos de bienes o intereses de Mascotalia
                o de terceros.
              </p>
              <p  >
                Igualmente queda terminantemente prohibida la utilización de la
                Plataforma de manera que se perjudique a los Usuarios o a
                terceros internautas. Los Usuarios se abstendrán de llevar a
                cabo cualquier acción que de cualquier otra forma sobrecarguen,
                dañen o inutilicen las redes, servidores y demás equipos
                informáticos (hardware) o productos y aplicaciones informáticas
                (software) de Mascotalia o de terceros.
              </p>
              <p  >
                Mascotalia se reserva el derecho de eliminar o modificar cualquier tipo
                de información suministrada por los Usuarios Propietarios o
                Usuarios Cuidadores si considera que la información tiene
                carácter lesivo.
              </p>
              <p  >
                En caso de que el Usuario detecte cualquier información o
                contenido ofrecido por terceros puede tener la consideración de
                ilícito, puede proceder a notificarlo a Mascotalia.
              </p>
              <p  >
                Mascotalia se reserva el derecho de monitorear y/o leer todos los
                mensajes que pasan a través de la Plataforma. Si el Usuario
                Cuidador o Usuario Propietario muestran interés en realizar la
                reserva por fuera Mascotalia dará de baja inmediata su perfil.
              </p>
              <p  >
                Mascotalia puede contactar por medio de teléfono, celular, WhatsApp o
                SMS el teléfono registrado en la Cuenta de Usuario para
                notificar o pedir información al Usuario Cuidador o Usuario
                Propietario. Mascotalia puede también contactar para resolver dudas,
                recolectar reseñas, recolectar dinero, resolver un problema o
                por problemas en la cuenta del Usuario Propietario o Usuario
                Cuidador.
              </p>
              <p  >
                LIMITACIÓN DE RESPONSABILIDAD
              </p>
              <p  >
                Mascotalia una empresa que pone a disposición a través la Plataforma un
                servicio para que Usuarios Propietarios se contacten con
                Usuarios Cuidadores y puedan contratar los servicios guardería,
                hospedaje y paseo de mascotas ofrecidos por éstos. La
                responsabilidad de Mascotalia se circunscribe únicamente a este ámbito
                de actividad.
              </p>
              <p  >
                En ningún caso Mascotalia será responsable por los daños y/o perjuicios
                (ya sean daños ocasionados por las mascotas o daños sufridos por
                éstas) que puedan causarse como consecuencia de los servicios
                prestados por los Usuarios Cuidadores contratados a través de la
                Plataforma.
              </p>
              <p  >
                Los Usuarios Cuidadores serán los únicos responsables de los
                daños que pudieren sufrir las mascotas de los Usuarios
                Propietarios mientras estuvieren a su cargo. Mascotalia queda exento de
                toda responsabilidad por tales daños, así como respecto de los
                daños que pudieren ocasionar los propios animales, sea a
                personas o cosas y que le fueren directamente imputables.
              </p>
              <p  >
                Los Usuarios Cuidadores se comprometen a solicitar a los
                Usuarios Propietarios, previamente a la prestación de los
                servicios, el carnet de vacunas al día de la mascota, y toda
                aquella documentación pertinente, así como también a tomar todas
                las medidas de necesarias para la seguridad de las mascotas de
                los Usuarios Propietarios y de las personas que habiten en la
                casa y/u otros terceros y/o animales. Los Usuarios serán los
                únicos responsables por cualquier incumplimiento o negligencia
                respecto de tales medidas de seguridad, quedando Mascotalia exento de
                toda responsabilidad al respecto.
              </p>
              <p  >
                Mascotalia no se hace responsable por daños materiales, extravío o robo
                de pertenencias en el servicio de “visita a casa”.
              </p>
              <p  >
                RENUNCIA DE GARANTÍAS
              </p>
              <p  >
                Los Usuarios reconocen que el rol de Mascotalia se limita a conectar a
                los Usuarios y no tiene control ni está obligado a tomar ninguna
                medida sobre:
              </p>
              <p  >
                <ol>
                  <li>
                    La veracidad y /o exactitud de los datos proporcionados por
                    los Usuarios al momento del registro.
                  </li>
                  <li>
                    El control sobre el desempeño y/o conducta de los Usuarios
                    Cuidadores en la prestación de sus servicios, más allá de su
                    admisión previa a través del Formulario de Admisión, o de la
                    conducta de cualquier otro usuario de la Plataforma.
                  </li>
                  <li>
                    La prevención de daños que puedan sufrir las mascotas de los
                    Usuarios Propietarios durante el tiempo que estuvieren bajo
                    cuidado de los Usuarios Cuidadores.
                  </li>
                  <li>
                    Los daños que puedan ocasionar las mascotas de los Usuarios
                    Propietarios durante el tiempo que estuvieren bajo cuidado
                    de los Usuarios Cuidadores.
                  </li>
                  <li>
                    Los Usuarios Cuidadores y Usuarios Propietarios liberan a
                    Mascotalia de toda responsabilidad por cualquier daño ya sea
                    directo, indirecto, general, especial, compensatorio,
                  </li>
                </ol>
              </p>
              <p  >
                INDEMNIDAD
              </p>
              <p  >
                El Usuario mantendrá indemne, defenderá y exonerará a Mascotalia de
                todo daño, responsabilidad y costo que pudiera surgir como
                consecuencia de un reclamo de terceros contra Mascotalia respecto del
                uso de los Servicios y/o la Plataforma por parte del Usuario,
                contrario a lo estipulado en estos T&amp;C’s.
              </p>
              <p  >
                MODIFICACIONES
              </p>
              <p  >
                Mascotalia se reserva el derecho de modificar o reemplazar cualquiera
                de los T&amp;C’s del Servicio, y de cambiar, suspender, e
                interrumpir los Servicios o las condiciones de acceso a los
                mismos en cualquier momento, previa notificación en la
                Plataforma, o mediante el envío de notas a través de los
                Servicios, correo electrónico o cualquier otro medio apropiado
                para comunicación electrónica.
              </p>
              <p  >
                Mascotalia se reserva el derecho a modificar funciones o prestaciones
                en los Servicios. El Usuario deberá revisar periódicamente los
                T&amp;C’s del Servicio periódicamente para mantenerse informado
                acerca de posibles cambios en los mismos.
              </p>
              <p>
                El uso continuado de los Servicios luego de efectuarse las
                modificaciones, implican una aceptación tácita de los cambios
                efectuados.
              </p>
              <p  >
                FUERZA MAYOR
              </p>
              <p  >
                Mascotalia no será responsable de los incumplimientos en que incurra
                siempre que dichos incumplimientos de deban a cuestiones que se
                encuentran más allá de su control razonable, incluyendo pero no
                limitando, cualquier catástrofe natural, huelga, cierre forzoso
                de cualquier índole, interrupción en el suministro de energía
                eléctrica, teléfono, telecomunicaciones, y cualquier otra causa
                natural, tecnológica, política o económica u otra, fuera de
                control de Mascotalia que no pueda ser superada mediante el empleo de
                diligencia razonable sin ocasionar gastos excesivos.
              </p>
              <p  >
                INCUMPLIMIENTO DE RETIRAR MASCOTAS
              </p>
              <p  >
                Si el Usuario Propietario realizó la reserva a un Usuario
                Cuidador registrado en Mascotalia y no ha recogido a su mascota pasado
                los 7 (siete) días de que se debió ser concretada, estás
                aceptando que Mascotalia puede (pero no tiene obligación) en discreción
                poner a la mascota del Usuario Propietario en adopción o perrera
                y se notificará a las autoridades correspondientes. El Usuario
                Propietario es el responsable de cubrir y reembolsar los gastos
                de tales acciones hechas por Mascotalia.
              </p>
              <p  >
                ACUERDO COMPLETO
              </p>
              <p  >
                T&amp;C’s enunciados constituyen el acuerdo completo entre el
                Usuario y Mascotalia respecto la Plataforma y los Servicios de la
                Plataforma. Estos T&amp;Cs sustituyen todas las comunicaciones y
                propuestas anteriores o contemporáneas (orales, escritas o
                electrónicas) entre el Usuario y Mascotalia.
              </p>
              <p  >
                Si alguna disposición de los T&amp;C’s resultaren inválidas, la
                disposición se limitará o eliminará en la medida mínima
                necesaria para que las Condiciones del Servicio continúen siendo
                ejecutables.
              </p>
              <p  >
                Si el Usuario o Mascotalia no pudiesen ejercer los derechos y
                facultades que emanan de estos T&amp;C’s, esto no se considerará
                una renuncia a la continuación de los demás derechos y
                facultades.
              </p>
              <p  >
                DOMICILIO
              </p>
              <p  >
                Se fija como domicilio de Mascotalia en C. Montes Urales 424, Lomas de Chapultepec, 11000, Ciudad de México.
              </p>
              <p  >
                LEY APLICABLE Y JURISDICCIÓN
              </p>
              <p  >
                Los presentes T&amp;C’s se regirán e interpretarán de
                conformidad con las leyes de los Estados Unidos Mexicanos.
              </p>
              <p  >
                Cualquier controversia derivada de los presentes T&amp;C’s, su
                existencia, validez, interpretación, alcance o cumplimiento,
                será sometida a la Jurisdicción de los Tribunales de los Estados
                Unidos Mexicanos.
              </p>
              <p  >
                LÍMITE DE RESPONSABILIDAD Y DIVULGACIÓN
              </p>
              <p  >
                Al registrarse como Usuario Cuidador y recibir una mascota para
                cuidado o proporcionar otros servicios de cuidador, el Usuario
                Cuidador asume expresamente el riesgo de daños y perjuicios,
                incluyendo daños a la propiedad o lesiones personales, que el
                Usuario Cuidador o cualquier otra persona presente en su
                residencia, pueden sufrir, salvo lo dispuesto en el Programa de
                cobertura. Asimismo, es exclusiva responsabilidad de los
                Usuarios Propietarios tomar decisiones que sean el mejor interés
                para ellos y sus mascotas. No obstante, se recomienda
                encarecidamente que todos los Usuarios Cuidadores programen un
                "Meet-n-Greet" o reunión con todas las mascotas y dueños de
                mascotas antes de proporcionar el servicio para mitigar
                cualquier riesgo como sea posible.
              </p>
              <p  >
                Al aceptar ser Usuario Cuidador (teniendo o no miembros menores
                a 18 años en su domicilio) el Usuario acepta y toma conocimiento
                de lo siguiente:
              </p>
              <p  >
                El Usuario y/o sus hijos / cónyuge / pupilo aceptan
                voluntariamente cuidar una mascota que pertenece a otro en su
                casa o en algún otro lugar de lo contrario. Por la máxima ley
                aplicable, el Usuario está asumiendo, en nombre de sí mismo y /
                o sus hijos / cónyuge / pupilo, todo el riesgo de lesiones
                personales, muerte o incapacidad para el Usuario y / o su hijo /
                cónyuge / pupilo que pudiera derivarse o surgir del cuidado o
                manejo de este animal doméstico, o cualquier daño, pérdida o
                destrucción a su razón o bienes personales que el Usuario o sus
                hijos / cónyuge / pupilo pueden incurrir, en cada caso, salvo lo
                dispuesto en el Programa de cobertura. El Usuario entiende los
                riesgos asociados con el cuidado o manejo de una mascota que no
                está acostumbrado barrio y su casa o en otro lugar que no tiene
                riesgos inherentes; y
              </p>
              <p  >
                El Usuario está de acuerdo en nombre de sí mismo y/o sus hijos /
                cónyuge / pupilo y sus / sus representantes personales,
                sucesores, herederos y cesionarios para mantener Mascotalia, sus
                afiliados, funcionarios, directores, agentes, empleados y
                miembros (colectivamente) "Exonerados" de cualquier tipo y todos
                los reclamos o causas de acción que surjan de la pensión o la
                manipulación de esta mascota, salvo lo dispuesto en el Programa
                de cobertura. El Usuario expresamente libera y exonera a Mascotalia de
                cualquier y toda responsabilidad, reclamo, demanda o causa de
                acción que surja de cualquier daño, pérdida, lesiones personales
                o la muerte de el Usuario y/o sus hijos / cónyuge / pupilo,
                durante el cuidado o cualquier otro servicio que se encuentre
                prestando, salvo lo dispuesto en el Programa de cobertura. Al
                continuar con la transacción o el uso la Plataforma de cualquier
                otro modo, el Usuario entiende que voluntariamente renuncia a su
                derecho de demandar a las partes antes mencionadas de acuerdo a
                lo expuesto más arriba.
              </p>
          
        </Card>
      </Container>
    </>
  );
}
