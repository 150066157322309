import {useState} from 'react';

import Header from '../components/landing-pet-owner/Header';
import MyNavbar from '../components/landing-pet-owner/MyNavbar';
import LandingForm from '../components/landing-pet-owner/LandingForm';
import Benefits from '../components/landing-pet-owner/Benefits';
import ModalContact from '../components/landing-pet-owner/ModalContact';

import QuioteSection from '../components/landing-pet-owner/QuoteSection'
import Footer from '../components/landing-pet-owner/Footer'
import Container from 'react-bootstrap/Container';
import {useParams} from "react-router-dom";

export default function LandingPetOwner() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState();
  
  let params = useParams();

  console.log('params', params.version)

  const title = params.version !== 'a' ? "ENCUENTRA UN PASEADOR DE PERROS DE CONFIANZA" : "ENCUENTRA TU VET DE CONFIANZA" 

  return (
    <div>
          <MyNavbar/>
          <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" className="scrollspy-example" tabIndex="0" >
          <Header title={title}/>
          <LandingForm show={show} handleClose={handleClose} handleShow={handleShow} setFormData={setFormData}/>
          <Benefits show={show} handleClose={handleClose} handleShow={handleShow} />
          <Container className="section-margin">
            <QuioteSection show={show} handleClose={handleClose} handleShow={handleShow}/>
          </Container>
          <Footer/>
          <ModalContact show={show} handleClose={handleClose} handleShow={handleShow} formData={formData}/>
          </div>
    </div>
  )
}
