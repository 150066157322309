import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import { FaPaw } from 'react-icons/fa';
import { FaCalendarAlt } from 'react-icons/fa';
import { FaWhatsappSquare } from 'react-icons/fa';
import { FaSearchDollar } from 'react-icons/fa';

import DogWalker from './DogWalker'


export default function Benefits({show, handleClose, handleShow}) {
  return (
    <>
   <Container id="benefits">
   <Row className="mt-4 mb-4">
   <h1 className="text-center">Beneficios</h1>
   </Row>
   <Row className="mt-4 mb-4">
    <Col xs="6" md="3">
        <Card className="p-4 green-bg white-text text-center mt-3">
            <h1><FaPaw/></h1>
            <h5>Encuentra a <br/> tu prestador de servicios.</h5>
        </Card>
    </Col>
    <Col xs="6" md="3">
        <Card className="p-4 green-bg white-text text-center mt-3">
            <h1><FaCalendarAlt /></h1>
            <h5>Pide una cita <br/> de forma fácil</h5>
        </Card>
    </Col>
    <Col xs="6" md="3">
        <Card className="p-4 green-bg white-text text-center mt-3">
            <h1><FaWhatsappSquare /></h1>
            <h5>Recordatorios <br/> vía WhatsApp</h5>
        </Card>
    </Col>
    <Col xs="6" md="3">
        <Card className="p-4 green-bg white-text text-center mt-3">
            <h1><FaSearchDollar /></h1>
            <h5>Sin costos <br/> ocultos</h5>
        </Card>
    </Col>
   </Row>
    </Container>

   <Container fluid className="green-bg white-text">
            <DogWalker show={show} si="hola" handleClose={handleClose} handleShow={handleShow} id="dog-walker"/>
      </Container>
      <Container>
   <Row className="align-items-center">
        <Col xs="12" md="8">
          <Card className="white-text green-bg p-4 mt-4">
             <Image src="./benefit_1.png" className="img-fluid d-block d-sm-none" style={{padding:"3rem"}} />
            <div className="p-4">
            <h2>Ubicación</h2>
            <p>Encuentra el consultorio, clínica u hospital veterinario dentro de un rango de distancia que establezcas en los filtros.
            Para usarlo simplemente ingresa tu ubicación y la distancia deseada en kilómetros</p>
            </div>
          </Card>
        </Col>
        <Col md="4" xs="12">
            <Image src="./benefit_1.png" className="img-fluid d-none d-sm-block" style={{padding:"3rem"}} />
        </Col>
      </Row>
     
   <Row className="align-items-center"> 
        
        <Col md="4" xs="12">
            <Image src="./benefit_2.png" className="img-fluid d-none d-sm-block" style={{padding:"3rem"}} />
        </Col>
        <Col xs="12" md="8">
          <Card className="white-text green-bg p-4 mt-4">
          <Image src="./benefit_2.png" className="img-fluid d-block d-sm-none" style={{padding:"3rem"}} />
            <div className="p-4">
            <h2>Busca por servicio o especialidad</h2>
            <p>Utiliza nuestro filtro para ahorrar tiempo. <br/>
Encuentra sólo las clínicas que ofrezcan el producto 
o servicio que estás buscando y agenda una cita.</p>
            </div>
          </Card>
        </Col>
       
      </Row>
      <Row className="align-items-center">
        <Col xs="12" md="8">
          <Card className="white-text green-bg p-4 mt-4 mb-4">
            <Image src="./benefit_3.png" className="img-fluid d-block d-sm-none" style={{padding:"3rem"}} />
            <div className="p-4">
            <h2>Reviews</h2>
            <p>Los clientes pueden calificar distintos rubros de su experiencia con las clínicas, para que puedas elegir con toda confianza a cual negocio llevar a tu mascota.</p>
            </div>
          </Card>
        </Col>
        <Col md="4" xs="12">
            <Image src="./benefit_3.png"  className="img-fluid d-none d-sm-block" style={{padding:"3rem"}} />
        </Col>
      </Row>
      
  </Container>
  </>
  )
}
